import React from "react"

import PropTypes from "prop-types"
import { Typography, Box } from "@mui/material"

const SegmentTitle = ({ subtitle, children, ...props }) => {
  return (
    <Box mb={2}>
      <Typography variant="h4" color="secondary" {...props}>
        {children}
      </Typography>
    </Box>
  )
}

SegmentTitle.propTypes = {
  children: PropTypes.node,
}

export default SegmentTitle
